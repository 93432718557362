import $ from 'jquery';

document.addEventListener('turbolinks:load', () => {
  $('#js-survey-questions').hide();
  $('#js-survey-answer-keys').hide();

  $('#js-show-survey-questions').on('click', function() {
    $('#js-survey-questions').toggle('slow');
  });
  $('#js-show-survey-answer-keys').on('click', function() {
    $('#js-survey-answer-keys').toggle('slow');
  });
});
